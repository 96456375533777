import Vue from 'vue'

import './styles/quasar.scss'
import 'quasar/dist/quasar.ie.polyfills'
import iconSet from 'quasar/icon-set/fontawesome-v5.js'
import lang from 'quasar/lang/pt-br.js'
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css'
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/Notify.js';import Loading from 'quasar/src/plugins/Loading.js';import BottomSheet from 'quasar/src/plugins/BottomSheet.js';import AddressbarColor from 'quasar/src/plugins/AddressbarColor.js';import AppFullscreen from 'quasar/src/plugins/AppFullscreen.js';import Dialog from 'quasar/src/plugins/Dialog.js';

// Needed only for iOS PWAs
if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream && window.navigator.standalone) {
  import(/* webpackChunkName: "fastclick"  */ '@quasar/fastclick')
}

Vue.use(Quasar, {
  config: {
    notify: {
      timeout: 5000,
      position: 'top-right',
      classes: 'text-bold',
      textColor: 'white',
      actions: [{ icon: 'fas fa-times', color: 'white' }]
    }
  },
  components: { /* not needed if importStrategy is not 'manual' */ },
  directives: { /* not needed if importStrategy is not 'manual' */ },
  plugins: {
    Notify,
    Loading,
    BottomSheet,
    AddressbarColor,
    AppFullscreen,
    Dialog
  },
  lang: lang,
  iconSet: iconSet
})
