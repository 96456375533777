const ShoppingLists = () => import(/* webpackChunkName: "shoppingLists" */ './Index.vue')
const ShoppingList = () => import(/* webpackChunkName: "shoppingList" */ './ShoppingList.vue')

const index = {
  path: '/shopping/lists',
  name: 'Listas de Compras',
  component: ShoppingLists,
  meta: {
    requiresLogin: true,
    icon: 'fas fa-list-ul',
    shown: true,
    subtitle: 'Lista de compras'
  }
}

const create = {
  path: 'shopping/list',
  name: 'Criar Lista de Compra',
  component: ShoppingList,
  meta: {
    requiresLogin: true,
    requiresAdministration: false,
    shown: false
  }
}

const edit = {
  path: 'shopping/list/:idLista',
  name: 'Editar Lista de Compra',
  component: ShoppingList,
  props: true,
  meta: {
    requiresLogin: true,
    requiresAdministration: false,
    shown: false
  }
}

export default {
  index,
  create,
  edit
}
