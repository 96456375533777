import Vue from 'vue'
import VueRouter from 'vue-router'
import beforeEach from './beforeEach'
// Components routes
import Login from '../views/login/index'
import Register from '../views/register/index'
import ConfirmMail from '../views/confirmMail/index'
import Home from '../views/home/index'
import User from '../views/user/index'
import Chats from '../views/chats/index'
import Error404 from '../views/error404/index'
import MaintenanceProduct from '../views/maintenanceProduct/index'
import MaintenanceCompanies from '../views/maintenceCompany/index'
import NFCe from '../views/nfce/index'
import Contribution from '../views/contribution/index'
import InfoProduct from '../views/infoProduct/index'
import ShoppingList from '../views/shoppingList/index'
import ShoppingCart from '../views/shoppingCart/index'
import Orders from '../views/orders/index'
// Containers
const Full = () => import(/* webpackChunkName: "full" */ '../layouts/Full.vue')
const Clean = () => import(/* webpackChunkName: "clean" */ '../layouts/Clean.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home',
    name: 'Full',
    component: Full,
    children: [
      Home.index,
      User.index,
      Chats.index,
      MaintenanceProduct.index,
      MaintenanceProduct.create,
      MaintenanceProduct.edit,
      MaintenanceCompanies.index,
      MaintenanceCompanies.create,
      MaintenanceCompanies.edit,
      NFCe.index,
      Contribution.index,
      InfoProduct.index,
      ShoppingList.index,
      ShoppingList.create,
      ShoppingList.edit,
      ShoppingCart.index,
      Orders.index
    ]
  },
  {
    path: '/',
    name: 'Clean',
    component: Clean,
    children: [
      Login.index,
      Register.index,
      ConfirmMail.index,
      Error404.index
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach(beforeEach)

export default router
