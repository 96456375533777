const state = {
  queue: []
}

const mutations = {
  GET_QUEUE (state) {
    return state.queue
  },

  SET_QUEUE (state, exec) {
    state.queue = [exec, ...state.queue]
  },

  REMOVE_FROM_QUEUE (state, idx) {
    state.queue = state.queue.filter((x, i) => i !== idx)
  }
}

const actions = {
  getQueue ({ commit }) {
    commit('GET_QUEUE')
  },

  setQueue ({ commit }, exec) {
    exec.status = 'PENDING'
    exec.response = {}
    const params = exec.params
    exec.fn(...params).then(response => {
      exec.status = 'SUCCESS'
      exec.response = response
    }).catch(err => {
      exec.status = 'ERROR'
      console.error(err)
    })
    commit('SET_QUEUE', exec)
  },

  removeFromQueue ({ commit }, idx) {
    commit('REMOVE_FROM_QUEUE', idx)
  }
}

const getters = {
  getQueue: state => state.queue
}

const module = {
  state,
  mutations,
  actions,
  getters
}

export default module
