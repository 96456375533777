const ConfirmMail = () => import(/* webpackChunkName: "login" */ './ConfirmMail.vue')

const index = {
  path: 'user/confirm-mail/:token',
  name: 'Confirmar E-mail',
  component: ConfirmMail,
  props: true,
  meta: {
    requiresLogin: false,
    shown: false
  }
}

export default {
  index
}
