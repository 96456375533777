const Index = () => import(/* webpackChunkName: "orders" */ './Index.vue')

const index = {
  path: 'orders',
  name: 'Pedidos',
  component: Index,
  meta: {
    requiresLogin: true,
    icon: 'fas fa-file-invoice',
    shown: true,
    subtitle: 'Compras efetuadas'
  }
}

export default {
  index
}
