const Index = () => import(/* webpackChunkName: "home" */ './Index.vue')

const index = {
  path: 'contribution',
  name: 'Contribuição',
  component: Index,
  meta: {
    requiresLogin: true,
    requiresAdministration: true,
    icon: 'fas fa-tags',
    shown: true,
    subtitle: 'Submissão de preço manual'
  }
}

export default {
  index
}
