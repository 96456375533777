import Vuex from 'vuex'
import Vue from 'vue'
import Queue from './vuex'
import SocketService from '../services/Socket.service'
Vue.use(Vuex)

const connectSocket = (user) => {
  if (user && user.cpf && !SocketService.socket.connected) {
    SocketService.socket.io.opts.query = {
      identification: user.cpf, name: user.nome
    }
    SocketService.socket.connect()
  }
}

const disconnectSocket = () => {
  if (SocketService.socket.connected) {
    SocketService.socket.disconnect()
  }
}

const authToken = JSON.parse(window.localStorage.getItem('authToken'))
const user = JSON.parse(window.localStorage.getItem('user'))
const darkMode = JSON.parse(window.localStorage.getItem('darkMode')) || false
const locality = JSON.parse(window.localStorage.getItem('locality')) || null
const purchase = JSON.parse(window.localStorage.getItem('purchase')) || null
const appMode = JSON.parse(window.localStorage.getItem('appMode')) || 'delivery'

const state = {
  appVersion: process.env.APP_VERSION || '0',
  authToken: authToken,
  user: user,
  darkMode: darkMode,
  locality: locality,
  purchase: purchase,
  appMode: appMode
}

connectSocket(user)

const mutations = {
  setAuthToken (state, value) {
    if (value) {
      window.localStorage.setItem('authToken', JSON.stringify(value))
    } else {
      window.localStorage.removeItem('authToken')
    }
    state.authToken = value || null
  },
  setUser (state, value) {
    if (value) {
      window.localStorage.setItem('user', JSON.stringify(value))
      connectSocket(value)
    } else {
      window.localStorage.removeItem('user')
      disconnectSocket()
    }
    state.user = value
  },
  setDarkMode (state, value) {
    if (value !== undefined && value !== null) {
      window.localStorage.setItem('darkMode', JSON.stringify(value))
    } else {
      window.localStorage.removeItem('darkMode')
    }
    state.darkMode = value
  },
  setLocality (state, value) {
    if (value !== undefined && value !== null) {
      window.localStorage.setItem('locality', JSON.stringify(value))
    } else {
      window.localStorage.removeItem('locality')
    }
    state.locality = value
  },
  setPurchase (state, value) {
    if (value) {
      window.localStorage.setItem('purchase', JSON.stringify(value))
    } else {
      window.localStorage.removeItem('purchase')
    }
    state.purchase = value
  },
  setItemPurchase (state, value) {
    if (value) {
      if (state.purchase.itens && state.purchase.itens.length) {
        const index = state.purchase.itens.findIndex((item) => parseInt(item.idProduto) === parseInt(value.idProduto))
        if (index !== -1) {
          const item = state.purchase.itens[index]
          item.quantidadeProduto = parseInt(value.quantidadeProduto)
        } else {
          state.purchase.itens.push(value)
        }
      } else {
        state.purchase.itens = [
          value
        ]
      }
      window.localStorage.setItem('purchase', JSON.stringify(state.purchase))
    }
  },
  setItensPurchase (state, value) {
    if (value && Array.isArray(value) && value.length > 0) {
      state.purchase.itens = value
      window.localStorage.setItem('purchase', JSON.stringify(state.purchase))
    } else {
      state.purchase = null
      window.localStorage.removeItem('purchase')
    }
  },
  setAppMode (state, value) {
    if (value !== undefined && value !== null) {
      window.localStorage.setItem('appMode', JSON.stringify(value))
    } else {
      window.localStorage.removeItem('appMode')
    }
    state.appMode = value
  }
}

const actions = {
  getAppVersion ({ commit }) {
    commit('getAppVersion')
  },
  setAuthToken ({ commit }, value) {
    commit('setAuthToken', value)
  },
  setUser ({ commit }, value) {
    commit('setUser', value)
  },
  setDarkMode ({ commit }, value) {
    commit('setDarkMode', value)
  },
  setLocality ({ commit }, value) {
    commit('setLocality', value)
  },
  setPurchase ({ commit }, value) {
    commit('setPurchase', value)
  },
  setItemPurchase ({ commit }, value) {
    commit('setItemPurchase', value)
  },
  setItensPurchase ({ commit }, value) {
    commit('setItensPurchase', value)
  },
  setAppMode ({ commit }, value) {
    commit('setAppMode', value)
  },
  getAuthToken ({ commit }) {
    commit('getAuthToken')
  },
  getUser ({ commit }) {
    commit('getUser')
  },
  getDarkMode ({ commit }) {
    commit('getDarkMode')
  },
  getLocality ({ commit }) {
    commit('getLocality')
  },
  getPurchase ({ commit }) {
    commit('getPurchase')
  },
  getAppMode ({ commit }) {
    commit('getAppMode')
  },
  ...Queue.actions
}

const getters = {
  getAppVersion () {
    return state.appVersion
  },
  getAuthToken () {
    return state.authToken
  },
  getUser () {
    return state.user
  },
  getDarkMode () {
    return state.darkMode
  },
  getLocality () {
    return state.locality
  },
  getPurchase () {
    return state.purchase
  },
  getAppMode () {
    return state.appMode
  }
}

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters
})
