const Index = () => import(/* webpackChunkName: "maintenanceProducts" */ './Index.vue')
const Produto = () => import(/* webpackChunkName: "maintenanceProduct" */ './Produto.vue')

const index = {
  path: 'maintenance/products',
  name: 'Produtos',
  component: Index,
  meta: {
    requiresLogin: true,
    requiresAdministration: true,
    icon: 'fas fa-search-plus',
    shown: true,
    subtitle: 'Manutenção de produtos'
  }
}

const create = {
  path: 'maintenance/product',
  name: 'Criar Produto',
  component: Produto,
  meta: {
    requiresLogin: true,
    requiresAdministration: true,
    shown: false
  }
}

const edit = {
  path: 'maintenance/product/:idProduto',
  name: 'Editar Produto',
  component: Produto,
  props: true,
  meta: {
    requiresLogin: true,
    requiresAdministration: true,
    shown: false
  }
}

export default {
  index,
  create,
  edit
}
