const Index = () => import(/* webpackChunkName: "chats" */ './Index.vue')

const index = {
  path: 'chats',
  name: 'Chats',
  component: Index,
  meta: {
    requiresLogin: true,
    icon: 'fas fa-comments',
    shown: true,
    subtitle: 'Minhas conversas'
  }
}

export default {
  index
}
