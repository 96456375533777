import io from 'socket.io-client'
import configs from '../configs'

const socket = io(configs.SOCKET_IP, {
  transport: ['websocket', 'polling'],
  autoConnect: false,
  reconnection: true,
  secure: true,
  rejectUnauthorized: false,
  path: '/echeap-socket/socket.io'
})

socket.on('connect', () => {
  console.log('Conectado Socket.IO')
})

socket.on('disconnect', () => {
  console.log('Desconectado Socket.IO')
})

export default {
  install (Vue, options) {
    Vue.prototype.$socket = socket
  },
  socket
}
