const NFCe = () => import(/* webpackChunkName: "contributionNFCe" */ './NFCe.vue')

const index = {
  path: 'contribution/nfce',
  name: 'Contribuição (NFC-e)',
  component: NFCe,
  meta: {
    requiresLogin: true,
    requiresAdministration: false,
    icon: 'fas fa-qrcode',
    shown: true,
    subtitle: 'Submissão de cupom eletrônico'
  }
}

export default {
  index
}
