const Index = () => import(/* webpackChunkName: "maintenanceCompanies" */ './Index.vue')
const Empresa = () => import(/* webpackChunkName: "maintenanceCompany" */ './Company.vue')

const index = {
  path: 'maintenance/companies',
  name: 'Empresas',
  component: Index,
  meta: {
    requiresLogin: true,
    requiresAdministration: true,
    icon: 'fas fa-building',
    shown: true,
    subtitle: 'Manutenção de empresas'
  }
}

const create = {
  path: 'maintenance/company',
  name: 'Criar Empresa',
  component: Empresa,
  meta: {
    requiresLogin: true,
    requiresAdministration: true,
    shown: false
  }
}

const edit = {
  path: 'maintenance/company/:cnpj',
  name: 'Editar Empresa',
  component: Empresa,
  props: true,
  meta: {
    requiresLogin: true,
    requiresAdministration: true,
    shown: false
  }
}

export default {
  index,
  create,
  edit
}
