const Register = () => import(/* webpackChunkName: "register" */ './Register.vue')

const index = {
  path: 'register',
  name: 'Register',
  component: Register,
  meta: {
    requiresLogin: false,
    shown: false
  }
}

export default {
  index
}
