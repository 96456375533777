const Error404 = () => import(/* webpackChunkName: "error404" */ './Error404.vue')

const index = {
  path: '*',
  name: '404 Error',
  component: Error404,
  meta: {
    requiresLogin: false,
    shown: false
  }
}

export default {
  index
}
