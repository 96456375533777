const InfoProduct = () => import(/* webpackChunkName: "infoProduct" */ './InfoProduct.vue')

const index = {
  path: 'product/:idProduto',
  name: 'Produto',
  component: InfoProduct,
  props: true,
  meta: {
    requiresLogin: true,
    requiresAdministration: false,
    shown: false
  }
}

export default {
  index
}
