import store from '../vuex/store'
import { sendNotification } from '../plugins/notify'

const beforeEach = (to, from, next) => {
  const authToken = store.getters.getAuthToken
  const user = store.getters.getUser
  if (!to.matched.length) {
    next({ path: '*' })
  } else {
    if (to.meta.requiresLogin && !authToken) {
      sendNotification({ color: 'info', message: 'Efetue o login no sistema.', timeout: 6000 })
      console.error('Você deve estar logado para acessar esse módulo.')
      next({ path: '/login' })
    } else {
      if (to.meta.requiresAdministration && !user.administration) {
        console.error('Você não tem permissão para acessar esse módulo.')
        next({ path: '/' })
      } else {
        next()
      }
    }
  }
}

export default beforeEach
