const Index = () => import(/* webpackChunkName: "shoppingCart" */ './Index.vue')

const index = {
  path: 'shopping/cart',
  name: 'Carrinho de Compras',
  component: Index,
  meta: {
    requiresLogin: true,
    icon: 'fas fa-shopping-cart',
    shown: false,
    subtitle: 'Produtos adicionados'
  }
}

export default {
  index
}
