const Profile = () => import(/* webpackChunkName: "profile" */ './Profile.vue')

const index = {
  path: 'profile',
  name: 'Perfil',
  component: Profile,
  meta: {
    requiresLogin: true,
    shown: false
  }
}

export default {
  index
}
