const Home = () => import(/* webpackChunkName: "home" */ './Home.vue')

const index = {
  path: 'home',
  name: 'Ínicio',
  component: Home,
  meta: {
    requiresLogin: true,
    icon: 'fas fa-home',
    shown: true,
    subtitle: 'Cotação de preço e promoções'
  }
}

export default {
  index
}
